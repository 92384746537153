import React, { Component } from 'react';
import styled from 'styled-components'
import BackgroundVideo from 'react-background-video-player'
import { Container, Row, Col } from 'styled-bootstrap-grid2'
import ContainerText from '../../components/container_text'
import SEO from '../../components/seo'
import bgvideo from './about_v3.mp4'
import bgvideo_mobile from './moonstone_mobile.mp4'
import ShopifyPage from '../../components/shopify_page'

let height = 0;
let width = 0;

const Desktop = styled.div`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`
const Mobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`

const VideoContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
  @media (max-width: 768px) {
    height: calc(100% - 94px);
  }

  video {
    left: 0px !important;
    @media (max-width: 768px) {
      height: calc(100vh - 94px);
    }
  }
`

const AboutText = styled.p`
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 27px;
  }
`
class IndexPage extends Component {

  updateDimensions() {
    if(typeof window !== 'undefined') {
      this.setState({ width: window.innerWidth, height: window.innerHeight});
    }
  };

  constructor(props) {
    super(props);
    if(typeof window !== 'undefined') {
      this.state = {
        height: window.innerHeight,
        width: window.innerWidth
      };
    } else {
      this.state = {
        height: '100%',
        width: '100%'
      };
    }
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    if(typeof window !== 'undefined') {
      window.addEventListener('resize', this.updateDimensions);
    }
  }

  componentWillUnmount() {
    if(typeof window !== 'undefined') {
      window.removeEventListener('resize', this.updateDimensions);
    }
  }

  // componentDidUpdate() {
  //   this.updateDimensions();
  // }

  render() {

    return (
      <div>
      <SEO title="About" keywords={[`About`, `Theodora`, `Warre`]} />
      <Desktop>
        <VideoContainer>
          <BackgroundVideo containerWidth={this.state.width} containerHeight={this.state.height} src={bgvideo} />
        </VideoContainer>
      </Desktop>
      <Mobile>
        <VideoContainer>
          <BackgroundVideo containerWidth={this.state.width} containerHeight={this.state.height} src={bgvideo_mobile} />
        </VideoContainer>
      </Mobile>
      <ContainerText>
        <Container>
          <Row>
            <Col xl="12" lg="12" md="12" sm="12">
              <AboutText>
                <ShopifyPage handle="about" />
              </AboutText>
            </Col>
          </Row>
        </Container>
      </ContainerText>
    </div>
    );
  }
}

export default IndexPage
